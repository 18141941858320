// ES6 module syntax
import LocalizedStrings from 'react-localization';
 
let strings = new LocalizedStrings({
    en:{
        home : "Home",
        product : "Product",
        products : "Products",
        aboutUs : "About Us",
        contact : "Contact",
        changeLanuage : "เปลี่ยนภาษา",
        catagory : "Catagory",
        fastShipping : "Fast Shipping",
        fastShippingDesc : "2-7 Days deliver",
        cheapestPrice : "Cheapest Price",
        cheapestPriceDesc : "Provide lowest price, high quality.",
        bestQuality : "Best Quality",
        bestQualityDesc : "Manufacture by professional workers",
        highDura : "High Durability",
        highDuraDesc : "Forget last time you replace",
        featured : "Featured Products",
        brands : "Brands",
        searchInput : "Find Product",
        status : "Status",
        howToBuy : "How to Buy",
        share : "Share",
        howToFromLine : "Add LINE and tell Code ",
        howToFromLaz : "Buy from ",
        howToFromFB : "Buy from ",
        inStock : "In Stock",
        name : "Name",
        subject : "Subject",
        message : "Message",
        submit : "Submit",
        phone : "Phone",
        fax : "Fax",
        email : "E-mail",
        dropQuestion : "Drop us a question.",
        contactSupp : "Contact our Support and Sales team.",
        isRequired : "This field is required.",
        gotQuestion : "Got Questions ? Call us",
        contactInfo : "Contact Info",
        address : "Thong Saeng metallic products Ltd.,part. 40 Soi Ekkachai 83/8 Ekkachai road,Bangbon, Bangkok 10150"

    },
    th: {
        home : "หน้าหลัก",
        product : "สินค้า",
        products : "สินค้า",
        aboutUs : "เกี่ยวกับเรา",
        contact : "ติดต่อเรา",
        changeLanuage : "Change Language",
        catagory : "หมวดสินค้า",
        fastShipping : "จัดส่งไว ไม่กระเทือน",
        fastShippingDesc : "จัดส่งไวใน 2-7 วัน ได้ของแน่นอน",
        cheapestPrice : "ราคาถูกที่สุด",
        cheapestPriceDesc : "ราคาถูกที่สุด และ คุณภาพดี",
        bestQuality : "คุณภาพดี",
        bestQualityDesc : "ผลิตโดยแรงงานคุณภาพ เพื่อสินค้าที่คุณภาพสูง",
        highDura : "คงทนนาน",
        highDuraDesc : "ลืมไปเลยเปลี่ยนครั้งสุดท้ายเมื่อไหร่",
        featured : "สินค้าแนะนำ",
        brands : "แบรนด์",
        searchInput : "ค้นหาสินค้า",
        status : "สถานะ",
        howToBuy : "การซื้อ",
        share : "แชร์",
        howToFromLine : "กดแอดไลน์พร้อมบอก Code ",
        howToFromLaz : "เลือกซื้อจากร้านค้าใน ",
        howToFromFB : "เลือกซื้อจาก ",
        inStock : "มีสินค้า",
        name : "ชื่อ",
        subject : "หัวข้อ",
        message : "ข้อความ",
        submit : "ส่ง",
        phone : "โทรศัพท์",
        fax : "แฟกซ์",
        email : "อีเมล์",
        dropQuestion : "มีคำถามหรอ",
        contactSupp : "ติดต่อทีมงานซัพพอร์ทและทีมงานขาย",
        isRequired : "ห้ามเว้นว่าง",
        contactInfo : "ติดต่อ",
        address : "48/10 หมู่ที่6 ถ.เอกชัย แขวงบางบอน เขตบางบอน กรุงเทพมหานคร 10150",
        gotQuestion : "มีคำถาม ? ติดต่อเรา"

    }
});

export default strings;