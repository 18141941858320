import React from "react";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CategoryIcon from '@material-ui/icons/Category';

import Localization from './Localization';

import { Link as RouterLink } from 'react-router-dom';


export default ({totalProducts, cat, textCat}) => {
  const listOfManu = () => {
    let arrayOfRenderList = cat.map((x,i) => {
      return (<div key={x.CatID}>
          <ListItem className="py-1" component={RouterLink} to={`/category/${x.CatID}`}>
            <ListItemIcon><ArrowRightIcon/></ListItemIcon>
            <ListItemText primary={x.CategoryName.toUpperCase()} />
          </ListItem>
          {i !== cat.length - 1 ? <Divider /> : <div></div>}
        </div>);
    });
    return arrayOfRenderList;
  }
  return <div className="">
        <div className="text-center bg-dark p-3 text-white">
          <h3><CategoryIcon /> {Localization.catagory}</h3>
          <h6>{totalProducts} {Localization.products}</h6>
        </div>
        <Divider />
        <div className="p-3">
          <List>
              {listOfManu()}
          </List>
        </div>
    </div>
}