import React, { Component } from 'react';
import axios from 'axios';

import Loading from '../component/Loading';

import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Divider } from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import EmailIcon from '@material-ui/icons/Email';

import FBIcon from '../icon/fb-black.png';
import ShopeeIcon from '../icon/shopee-black.png';
import LineIcon from '../icon/line-black.png';
import IconButton from '@material-ui/core/IconButton';
import LineFriend from '../component/LineFriend';

import Localization from '../component/Localization';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ReCAPTCHA from "react-google-recaptcha";
import qs from "qs";
import { withSnackbar } from 'notistack';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isLoading : true,
      noTry : 0,
      subject : "",
      message:"",
      name:"",
      // siteKey : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
      captchaValue : "",
      pleaseVerify : "",
      siteKey:"6LfjqMQUAAAAAImgI3GqhisArDu2zogB1MY97neY",
     };
  }

  componentDidMount() {
    console.log(Localization.getLanguage(), Localization.getInterfaceLanguage(),localStorage.getItem("language"));
    document.title = `ธงแสงโลหะภัณฑ์ - ${Localization.contact}`;
    this.setState({isLoading: false});
  }

  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    this.setState({captchaValue : recaptchaToken});
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  

  handleSubmit = async(e) => {
      // your submit logic
      
      if (this.state.captchaValue) {//have check
        await this.setState({pleaseVerify : "", isLoading:"Sending your mail ✉️"});
        let result = await axios.post("/recap.php",
          qs.stringify({
            subject : this.state.subject,
            message : `Name : ${this.state.name} \r\n Message : ${this.state.message}`,
            "g-recaptcha-response" : this.state.captchaValue
          }) 
        );

        console.log(this.state.subject, this.state.name, this.state.message, this.state.captchaValue);
        if (result.data === "Success") {//success sub
          await this.setState({isLoading : false, subject : "", message: "", name: ""});
          this.props.enqueueSnackbar("Success", { 
            variant: 'success',
          });

        }
        else {
          await this.setState({isLoading : false});
          this.props.enqueueSnackbar("Contact by e-mail instead", { 
            variant: 'error',
          });
        }
      }
      else {//no check
        this.setState({pleaseVerify : "Please verify yourself"});
        this.props.enqueueSnackbar("Check the box.", { 
          variant: 'error',
        });
      }
  }

 
  render() {
    if (this.state.isLoading) {
      return <Loading text={this.state.isLoading} noTry={this.state.noTry}/>
    }
    return (
      <div className="bg-white p-3 shadow-1 rounded container mt-5">
        <div className="row p-5">
         <div className="bg-cat-2 col-md-6 col-12 d-lg-flex justify-content-center d-none">
          <div className="align-items-center d-flex flex-column justify-content-center">
            <h2 className="text-yellow">{Localization.dropQuestion}</h2>
            <h5 className="text-white">{Localization.contactSupp}</h5>
          </div>
         </div>
         <div className=" col-md-6 col-12">
              
                <div className="col-12 my-3">
                  <h1>{Localization.contact}</h1>
                  <ValidatorForm className=" d-flex justify-content-center flex-column" onSubmit={this.handleSubmit}>
                    <TextValidator
                        label={Localization.name}
                        onChange={this.handleInputChange}
                        name="name"
                        value={this.state.name}
                        validators={['required']}
                        errorMessages={[Localization.isRequired]}
                    />
                    <TextValidator
                        label={Localization.subject}
                        onChange={this.handleInputChange}
                        name="subject"
                        value={this.state.subject}
                        validators={['required']}
                        errorMessages={[Localization.isRequired]}
                    />
                    <TextValidator
                        label={Localization.message}
                        onChange={this.handleInputChange}
                        name="message"
                        value={this.state.message}
                        validators={['required']}
                        errorMessages={[Localization.isRequired]}
                        multiline={true}
                        rows={5}
                        rowsMax={10}
                    />
                    <div className="d-flex justify-content-center flex-column align-items-center my-3">
                      <h6 className="text-danger">{this.state.pleaseVerify}</h6>
                      <ReCAPTCHA 
                        sitekey={this.state.siteKey}
                        onChange={this.verifyCallback}
                      />
                    </div>
                    <Button variant="outlined" type="submit">{Localization.submit}</Button>
                  </ValidatorForm>
                </div>

                <Divider/>

                <div className="col-12 text-left my-3">
                  <div className="row">
                    <div className="col-md-6 col-12 d-flex flex-column">
                      <h5>{Localization.phone}</h5>
                      <a href="tel:+6602415641"><h6 className="flip">(+66)02-4151641</h6></a>
                      <a href="tel:+66024152787"><h6 className="flip">(+66)02-4152787</h6></a>
                      <a href="tel:+66024152613"><h6 className="flip">(+66)02-4152613</h6></a>
                    </div>
                    
                    <div className="col-md-6 col-12 d-flex flex-column">
                      <h5>{Localization.fax}</h5>
                      <a href="tel:024154848"><h6 className="flip">02-415-4848</h6></a>
                    </div>

                    <div className="col-md-6 col-12 d-flex flex-column">
                      <h5>{Localization.email}</h5>
                      <a href="mailto:support@ts1971.com"><h6 className="flip">support@ts1971.com</h6></a>
                    </div>

                  </div>
                </div>

                <div className="col-12 text-left my-3">
                  <div className="pt-3">
                    <div style={{maxHeight:"64px", height:"32px", width:"32px"}} className="d-flex flex-row align-items-center mb-3">
                      <img src={LineIcon} className="img-fluid mx-3 img-yellow w-100" alt="LINE ICON"/>
                      <LineFriend classNam=""/>
                    </div>
                  </div>
                  <Divider variant="middle" />
                  <div className="my-3">
                    <div style={{maxHeight:"64px", height:"32px", width:"32px"}} className="d-flex flex-row align-items-center mb-3">
                      <img src={ShopeeIcon} className="img-fluid mx-3 img-yellow w-100" alt="SHOPEE ICON"/>
                      <a href="https://shopee.co.th/rexdenki"><span>Shopee</span></a>
                    </div>
                  </div>
                  <Divider variant="middle" />
                  <div className="my-3">
                    <div style={{maxHeight:"64px", height:"32px", width:"32px"}} className="d-flex flex-row align-items-center mb-3">
                      <img src={FBIcon} className="img-fluid mx-3 img-yellow w-100" alt="FB ICON"/>
                      <a href="https://www.facebook.com/REX-DENKI-413319258834527/"><span>Facebook</span></a>
                    </div>
                  </div>
                </div>

         </div>
        </div>
      </div>
    );
  }
}
 
export default withSnackbar(App);