import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';



export default function LineFriend({text,noTry}) {


    return (
    <div>
       <div className="container d-flex vh-100 justify-content-center">
          <div className="align-self-center align-items-center d-flex">
            <div>
              <CircularProgress />
            </div>
            <div className="px-3">
              <h4>{text}</h4>
              {noTry ? <h6>Number of try : {noTry}</h6> : <h6>Be patient. <span aria-label="Blue Heart" role="img">💙</span></h6>}
            </div>
          </div>
        </div>
    </div>
    );
  
}