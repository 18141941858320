import React, { Component } from 'react';
import axios from 'axios';
import FeatureCat from '../component/FeatureCat';

import Loading from '../component/Loading';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Localization from '../component/Localization';
 
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isLoading : true,
      cat : null,
      noTry : 0,
     };
  }

  componentDidMount() {
    document.title = `ธงแสงโลหะภัณฑ์ - ${Localization.brands}`;
    this.getData();
  }

  getData = async() => {
    if (this.state.noTry === 10) {
      this.setState({isLoading : "Try again later, Server's offline."});
    }
    else {
      try {
        await this.setState({isLoading : "Getting Category"});
        let resultCat = await axios.get("/pullCat.php");
        await this.setState({cat : resultCat.data, isLoading : false});
      }
      catch(e) {
        setTimeout(() => { this.setState({noTry : this.state.noTry+=1}); this.getData(); }, 3000);
      }
    }
    
    
  }
 
 
  render() {
    if (this.state.isLoading) {
      return <Loading text={this.state.isLoading} noTry={this.state.noTry}/>
    }
    return (
      <div className="bg-white p-3 shadow-1 rounded container mt-5">
        <div className="">
          <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" to="/" component={RouterLink} className="anchor-change">
                <span>{Localization.home}</span>
              </Link>
              <Typography color="textPrimary"><span>{Localization.brands}</span></Typography>
            </Breadcrumbs>
            <div className="div-divider my-3 d-block d-lg-none"></div>
            <div className="text-md-right text-center"><h4 className="text-uppercase underline-head pb-2 pt-3">{Localization.brands}</h4></div>
          </div>
          <div className="div-divider my-3 d-none d-lg-block"></div>
          <FeatureCat cat={this.state.cat} />
        </div>
      </div>
    );
  }
}
 
export default App;