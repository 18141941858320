
import React, { Component } from 'react';

import '../App.css';
import axios from 'axios';
import SlideTop from '../component/SlideTop';
import SideCat from '../component/SideCat';
import TopSell from '../component/TopSell';
import FeatureCat from '../component/FeatureCat';

import ShopeeLogo from '../picture/shopee.png';
import LineLogo from '../picture/line.png';
import FBLogo from '../picture/facebook.png';

import SearchBox from '../component/Search';
import CallIcon from '@material-ui/icons/Call';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';

import LineFriend from '../component/LineFriend';
import Loading from '../component/Loading';


import Localization from '../component/Localization';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:true,
      products : [],
      cat : [],
      noTry : 0,
    }
  }

  componentDidMount() {
    document.title = 'ธงแสงโลหะภัณฑ์';
    this.getData();
  }

  getData = async() => {
    if (this.state.noTry === 10) {
      this.setState({isLoading : "Try again later, Server's offline."});
    }
    else {
      try {
        await this.setState({isLoading : "Getting Products."});
        let resultProduct = await axios.get("/pullProducts.php");
        await this.setState({isLoading : "Getting Category."});
        let resultCat = await axios.get("/pullCat.php");
        this.setState({
          products : resultProduct.data,
          cat : resultCat.data,
          isLoading : false,
        });
      }
      catch(e) {
        setTimeout(() => { this.setState({noTry : this.state.noTry+=1}); this.getData(); }, 3000);
      }
    }
    
  }

  render() {
    if (this.state.isLoading) {
      return <Loading text={this.state.isLoading} noTry={this.state.noTry}/>
    }
    return (
      <div>
        <div className="bg-white">
          <SlideTop/>
        </div>
        <div className="container">
          <div className="my-3 bg-white">
            <div className="row text-center p-2">

              <a className="col-4" href="https://shopee.co.th/rexdenki">
                <div>
                  <img src={ShopeeLogo} className="img-fluid w-50" alt="Shopee"/>
                </div>
                <p className="text-uppercase">tsmotor</p>
              </a>
              <div className="col-4">
                <div>
                  <img src={LineLogo} className="img-fluid w-50"  alt="LINE"/>
                </div>
                
                <p className="text-uppercase">@ts1971</p>
                <LineFriend />
              </div>
              <a className="col-4" href="https://www.facebook.com/REX-DENKI-413319258834527/">
                <div>
                  <img src={FBLogo} className="img-fluid w-50"  alt="FB"/>
                </div>
                <p className="text-uppercase">REX DENKI</p>
              </a>

            </div>
          </div>

          <div className="mb-3">
            <div className="p-5 bg-white">
              <div className="row">

                
                <div className="col-md-3 col-12 d-flex justify-content-md-start justfy-content-center flex-row">
                  <AttachMoneyOutlinedIcon style={{fontSize:"3em"}} className="m-md-3 m-3 align-self-center text-yellow"/>
                  <div>
                    <h3>{Localization.cheapestPrice}</h3>
                    <p className="text-grey">{Localization.cheapestPriceDesc}</p>
                  </div>
                </div>
                <div className="col-md-3 col-12 d-flex justify-content-md-start justfy-content-center flex-row">
                  <LocalShippingOutlinedIcon style={{fontSize:"3em"}} className="m-md-3 m-3 align-self-center text-yellow"/>
                  <div>
                    <h3>{Localization.fastShipping}</h3>
                    <p className="text-grey">{Localization.fastShippingDesc}</p>
                  </div>
                </div>
                <div className="col-md-3 col-12 d-flex justify-content-md-start justfy-content-center flex-row">
                  <ThumbUpAltOutlinedIcon style={{fontSize:"3em"}} className="m-md-3 m-3 align-self-center text-yellow"/>
                  <div>
                    <h3>{Localization.bestQuality}</h3>
                    <p className="text-grey">{Localization.bestQualityDesc}</p>
                  </div>
                </div>
                <div className="col-md-3 col-12 d-flex justify-content-md-start justfy-content-center flex-row">
                  <AccessTimeOutlinedIcon style={{fontSize:"3em"}} className="m-md-3 m-3 align-self-center text-yellow"/>
                  <div>
                    <h3>{Localization.highDura}</h3>
                    <p className="text-grey">{Localization.highDuraDesc}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="pt-3 bg-white p-2">
            <div className="d-flex justify-content-between flex-row align-items-center align-items-md-start">
              <div className="d-none d-lg-block border-yellow">
                <SideCat totalProducts={this.state.products.length} cat={this.state.cat}/>
              </div>
              <div className="">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <SearchBox className="px-5" products = {this.state.products} />
                  <CallIcon className="d-none d-lg-block mx-3"/> 
                  <div className="d-none d-lg-flex px-2 justify-content-center flex-column">
                    <a href="tel:+6602415641"><h6 className="flip">(+66)02-4151641</h6></a>
                    <a href="tel:+66024152787"><h6 className="flip">(+66)02-4152787</h6></a>
                    <a href="tel:+66024152613"><h6 className="flip">(+66)02-4152613</h6></a>
                  </div>
                  
                </div>
                <TopSell products={this.state.products} />
                <FeatureCat cat={this.state.cat} />
              </div>
            </div>
          </div>



        </div>
      </div>
    );
  }
}

export default App;