import React  from "react";
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import Localization from './Localization';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import {Link as RouterLink} from 'react-router-dom';

export default ({products}) => {
  const useStyles = makeStyles({
    card: {
      width: "200px",
      height: "400px",
      maxWidth:"200px",
      maxHeight: "400px",
    },
    media: {
      height: "200px",
      width : "200px",
      objectFit: "cover"
    },
  });

  const classes = useStyles();

  
  const renderFeature = () => {
    
    const fiveProducts = [
      products[Math.floor(Math.random() * products.length)],
      products[Math.floor(Math.random() * products.length)],
      products[Math.floor(Math.random() * products.length)],
    ];
    let renderThis = fiveProducts.map(x => {
      return <Card component={RouterLink} to={`/category/${x.CatID}/${x.ProductID}`} className="m-3" style={{maxWidth:"200px", maxHeight : "400px", minHeight:"300px"}} key={x.ProductID}>
              <CardActionArea>
                <div className="d-flex justify-content-between align-items-center flex-column">
                  <div className="p-1 d-flex justify-content-center">
                    <CardMedia
                      className={classes.media}
                      image={require(`../picture/${x.Picture}`)}
                      alt={x.ProductName}
                    />
                  </div>
                  <div className="px-3">
                    <p className="font-weight-lighter text-grey">
                      {x.ProductName}
                    </p>
                    <Divider />
                    <div className="d-flex justify-content-between align-items-center p-2 pb-n2">
                      <div>
                        <p className="text-grey">Code : {x.ProductCode}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardActionArea>
            </Card>
    });

  return renderThis;
  }
  return <div className="d-flex flex-column p-3">
    <div className="text-md-right text-center"><h4 className="text-uppercase underline-head pb-2">{Localization.featured}</h4></div>
    <div className="d-flex justify-content-center align-items-center flex-md-row flex-column">{renderFeature()}</div>
  </div>
}