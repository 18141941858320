import React from "react";
import ShopeeIcon from '../icon/shopee.png';
import LineIcon from '../icon/line-icon.png';
import FBIcon from '../icon/fb.png';
import PhoneIcon from '@material-ui/icons/Phone';

import Localization from '../component/Localization';

export default ({cat}) => {
    return (
        <div style={{position:"sticky",bottom:"0"}}>
            <div className="d-flex flex-md-row flex-column justify-content-around align-items-center">
                {/* <div>
                    <div style={{width:"150px"}}>
                        <img src={Logo} className="img-fluid"/>
                    </div>
                </div> */}
                <div className="d-flex align-items-center">
                    <PhoneIcon fontSize="inherit" style={{fontSize:"48px"}} className="text-yellow"/>
                    <div className="p-4 d-flex flex-column">
                        <h5>{Localization.gotQuestion}</h5>
                        <a href="tel:+6602415641"><h6 className="text-yellow">(+66)02-4151641</h6></a>
                        <a href="tel:+66024152787"><h6 className="text-yellow">(+66)02-4152787</h6></a>
                        <a href="tel:+66024152613"><h6 className="text-yellow">(+66)02-4152613</h6></a>
                        <h5>{Localization.fax}</h5>
                        <a href="tel:024154848"><h6 className="text-yellow">02-415-4848</h6></a>
                    </div>
                </div>
                <div>
                    <div className="">
                        <h4>{Localization.contactInfo}</h4>
                        <p className="font-weight-light">{Localization.address}</p>
                    </div>
                    <div className="d-flex flex-row justify-content-md-start justify-content-center">
                        <a className="d-block" href="https://www.facebook.com/REX-DENKI-413319258834527/" style={{maxHeight:"64px", height:"32px", width:"32px"}}>
                            <img src={FBIcon} className="img-fluid" alt="FB"/>
                        </a>
                        <a className="d-block" href="https://shopee.co.th/rexdenki" style={{maxHeight:"64px", height:"32px", width:"32px"}}>
                            <img src={ShopeeIcon} className="img-fluid" alt="Shopee"/>
                        </a>
                        <a className="d-block" href="https://www.ts1971.com/#/contact" style={{maxHeight:"64px", height:"32px", width:"32px"}}>
                            <img src={LineIcon} className="img-fluid" alt="LINE"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}