import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {HashRouter} from 'react-router-dom'

import 'jquery/dist/jquery'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'
import { SnackbarProvider } from 'notistack';

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({ //override font for MUI only , Bootstrap 4 override font is in App.css
  typography: {
      fontFamily: `'Kanit', sans-serif`
  }
});


ReactDOM.render(
        
                <HashRouter>
                        <MuiThemeProvider theme={theme}>
                                <SnackbarProvider 
                                        maxSnack={3}anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                }}>
                                        <App/>
                                </SnackbarProvider>
                        </MuiThemeProvider>
                </HashRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
