import React, { Component } from 'react';
import axios from 'axios';
import FeatureCat from '../component/FeatureCat';

import Loading from '../component/Loading';
import Img1 from '../images/001.jpg';
import Img2 from '../images/002.jpg';
import Localization from '../component/Localization'
 
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isLoading : false,
      cat : null,
      noTry : 0,
     };
  }

  componentDidMount() {
    document.title = `ธงแสงโลหะภัณฑ์ - ${Localization.aboutUs}`;
  }


 
  render() {
    if (this.state.isLoading) {
      return <Loading text={this.state.isLoading} noTry={this.state.noTry}/>
    }
    return (
      <div className="bg-white p-3 shadow-1 rounded container mt-5">
        <div className="row d-flex align-items-center my-3">
          <div className="col-md-6 col-12 order-md-0 order-1">
            <img src={Img1} className="img-fluid w-100" />
          </div>
          <div className="col-md-6 col-12 order-md-1 order-0">
            <h1>ประวัติ หจก.ธงแสง</h1>
            <p className="font-weight-light" style={{textIndent: "2em"}}>
            ใน ปี พ.ศ.2509 สินค้าประเภทอะไหล่รถยนต์เป็นสินค้าที่นำเข้าจากต่างประเทศ 
            ซึ่งมีราคาสูง จึงทำให้ คุณแสงทวี ตันติวรศรี (ผู้ก่อตั้ง) 
            มีแนวคิดที่จะผลิตสินค้าราคาถูกและคุณภาพดีสามารถใช้ทดแทนอะไหล่แท้ 
            ได้ในราคาสมเหตุสมผล ท่านมองเห็นว่ารถยนต์หนึ่งคันประกอบไปด้วยชิ้นส่วนหลายส่วน 
            หนึ่งในนั้นคืออุปกรณ์ไฟให้สัญญาณในรถยนต์ ไม่ว่ารถยนต์จะมีการพัฒนาไปในรูปแบบใด 
            อุปกรณ์ให้สัญญาณก็ยังคงต้องใช้ โดยมี 3สี ที่เป็นมาตรฐานสากล สีแดง (แทนการชะลอรถ) 
            สีเหลือง (แทนการเปลี่ยนช่องเดินรถ) สีขาว (แทนการถอยหลัง) จึงทำให้ คุณแสงทวี มั่นใจว่าธุรกิจนี้ 
            จะอยู่คู่กับอุตสาหกรรมยานยนต์ ไปอีกนานและเล็งเห็นว่าจะมีการเติบโตอย่างต่อเนื่องในอนาคต
            </p>
          </div>
        </div>

        <div className="row d-flex align-items-center">
          <div className="col-md-6 col-12">
            <h1>ข้อมูลบริษัท</h1>
            <div className="font-weight-light row">
              <div className="col-6">
                ประเภทกิจการ
              </div>
              <div className="col-6">
                ห้างหุ้นส่วนจำกัด(หจก.)
              </div>
              <div className="col-6">
                ก่อตั้ง
              </div>
              <div className="col-6">
                1971
              </div>
              <div className="col-6">
                เลขทะเบียนนิติบุคคล
              </div>
              <div className="col-6">
                0103519005084
              </div>
              <div className="col-6">
                วันที่จดทะเบียน
              </div>
              <div className="col-6">
                23/03/1976>
              </div>
              <div className="col-6">
                ทุนจดทะเบียน
              </div>
              <div className="col-6">
                20,000,000
              </div>
              <div className="col-6">
                ที่ตั้ง
              </div>
              <div className="col-6">
                48/10 หมู่ที่6 ถ.เอกชัย แขวงบางบอน เขตบางบอน กรุงเทพมหานคร 10150
              </div>
              <div className="col-6">
                ประเภทธุรกิจ
              </div>
              <div className="col-6">
                ผลิตและจำหน่ายสัญญานไฟรถยนต์ ทั้งในประเทศและต่างประเทศ
              </div>
              <div className="col-6">
                ขนาดธุรกิจ
              </div>
              <div className="col-6">
                ขนาดกลาง
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <img src={Img2} className="img-fluid w-100" />
          </div>
        </div>
      </div>
    );
  }
}
 
export default App;