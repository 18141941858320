// This example is live editable
import React, { Component } from 'react';


import Home from './screen/Home';
import Cat from './screen/Cat';
import CatIndex from './screen/CatIndex';
import { Route } from 'react-router-dom';
import NavBar from './component/Navbar';
import Contact from './component/Contact';
import Product from './screen/Product';
import { Divider } from '@material-ui/core';
import ContactPage from './screen/Contact';
import About from './screen/About';


//https://codesandbox.io/s/n7q6kw9n8m?from-embed
class App extends Component {
  componentDidMount() {

  }
  render() {

    return (
      <div className="bg-background-grey">
        <div className="p-4">
          <NavBar/>
        </div>
        <div>
          <Route exact path='/' component={Home}/> 
          <Route exact path='/category' component={CatIndex}/> 
          <Route exact path='/category/:cat' component={Cat} />
          <Route exact path='/category/:cat/:product' component={Product} />
          <Route exact path='/contact' component={ContactPage} />
          <Route exact path='/about' component={About} />

        </div>
        <div className="container my-4">
          <Divider variant="middle" />
        </div>
        <div className="container pb-5">
          <Contact />
        </div>
      </div>
    );
  }
}

export default App;