import React from 'react';
import Localization from './Localization';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link as RouterLink} from 'react-router-dom';
import "bootstrap/js/src/collapse.js";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language : localStorage.getItem("language") || "th",
      languageButton : false,
      anchorEl : null,
    }
  }

  componentDidMount() {
    if (this.state.language === "en") {
      Localization.setLanguage("en");
      this.setState({});
    }
    else {
      Localization.setLanguage("th");
      this.setState({});
    }
  }

  languageButtonClick = (e) => {
    this.setState({anchorEl : e.currentTarget })
  }

  handleClose = () => {
    this.setState({anchorEl : null})
  }

  changeLanguage = (lang) => {
    if (lang === "en") {
      localStorage.setItem("language", lang);
      Localization.setLanguage("en");
    }
    else {
      localStorage.setItem("language", lang);
      Localization.setLanguage("th");
    }
    window.location.reload(true);
  }

    render() {
        return (
          <nav className="navbar navbar-light navbar-expand-md bg-success justify-content-between bg-white shadow-1 fixed-top">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse dual-nav w-50 order-1 order-md-0">
                    <ul className="navbar-nav">
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                          <Button className="nav-link" component={RouterLink} to="" ><h6 className="text-uppercase">{Localization.home}</h6></Button>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                          <Button className="nav-link" component={RouterLink} to="/category" ><h6 className="text-uppercase">{Localization.products}</h6></Button>
                        </li>
                    </ul>
                </div>
                <a href="/" className="navbar-brand mx-auto d-block text-center order-0 order-md-1 w-25">
                  <h3 className="font-weight-bold text-uppercase">TS 1971</h3>
                </a>
                <div className="navbar-collapse collapse dual-nav w-50 order-2">
                    <ul className="nav navbar-nav ml-auto">
                        
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                          <Button className="nav-link" component={RouterLink} to="/about" ><h6 className="text-uppercase">{Localization.aboutUs}</h6></Button>
                        </li>
                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                          <Button className="nav-link" component={RouterLink} to="/contact" ><h6 className="text-uppercase">{Localization.contact}</h6></Button>
                        </li>
                        <li className="nav-item">
                          <Button className="nav-link" onClick={this.languageButtonClick}><h6 className="flip">{localStorage.getItem("language") === "en" ? "ภาษา" : "Language"}</h6></Button>
                        </li>

                        <Menu
                          id="simple-menu"
                          keepMounted
                          anchorEl={this.state.anchorEl}
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleClose}
                          variant="menu"
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            <MenuItem className="m-0" onClick={(e) => {this.handleClose(e); this.changeLanguage("th")}}><p className="text-muted m-0 font-weight-light">Thai : ไทย</p></MenuItem>
                            <MenuItem className="m-0" onClick={(e) => {this.handleClose(e); this.changeLanguage("en")}}><p className="text-muted m-0 font-weight-light">English : อังกฤษ</p></MenuItem>
                        </Menu>
                    </ul>
                </div>
            </div>
        </nav>

        );
      }
}

export default NavBar;




