import React from "react";
import Slider from "react-slick";


export default function MediaCard() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      
    };
    return (
      <div className="bg py-5">
        <Slider {...settings}>
          <div>
            <div className="d-flex justify-content-center align-items-center p-3 flex-column flex-md-row">
              <div className="p-3">
                <img style={{maxHeight:"200px"}} className="img-fluid" src={require('../images/logo.png')} alt="FIRST SLIDE"/>
              </div>
              <div className="p-3 text-center">
                <h1 className="text-uppercase text-danger">safety first</h1>
                <h5 className="text-white">ผลิตด้วยเครื่องจักรที่ทันสมัย <br></br>และปลอดภัยผลิตด้วยเครื่องจักรที่ทันสมัย และปลอดภัย</h5>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  
}