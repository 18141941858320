import React from "react";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import Localization from './Localization';

import { Link as RouterLink } from 'react-router-dom';

export default ({cat}) => {
    const renderCard = () => {
        let cards = cat.map(x => {
            return <Card className="m-1 m-md-2" key={x.CatID} component={RouterLink} to={`/category/${x.CatID}` }>
            <CardActionArea className="d-flex justify-content-between align-items-center flex-column">
                <div className="p-1 d-flex justify-content-center" style={{width:"100px",height:"100px"}}>
                    <img
                        src={require(`../picture/${x.Picture}`)}
                        title="Contemplative Reptile"
                        className="img-fluid"
                        alt={x.CategoryName}
                    />
                </div>
            </CardActionArea>
        </Card>
        });
        return cards;
    }
    return <div className="d-flex flex-column p-3">
        <div className="d-flex align-content-center justify-content-center flex-wrap">
            {renderCard()}
        </div>
    </div>
}