import React, { Component } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';

import Loading from '../component/Loading';

import { Link as RouterLink } from 'react-router-dom';
import Localization from '../component/Localization';

const theme = createMuiTheme();
 
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      offset: 0,
      products : null,
      isLoading : true,
      currentProductsView : null,
      total : 500,
      cat : null,
      steps : 30,
      noTry : 0,
      currentCatName : "",
     };
  }

  componentDidMount() {
    document.title = `ธงแสงโลหะภัณฑ์ - ${Localization.brands} - ${this.state.currentCatName}`;
    if (this.props.match.params.cat) {
      this.getData();
    }
    else {
      console.log("NOPE")
      this.props.history.push('/category');
    }
  }

  getData = async() => {
    if (this.state.noTry === 10) {
      this.setState({isLoading : "Try again later, Server's offline."});
    }
    else {
      try {
        await this.setState({isLoading : "Getting Products"});
        let resultProduct = await axios.get("/pullProducts.php");
        await this.setState({isLoading : "Getting Category"});
        let resultCat = await axios.get("/pullCat.php");

        let catString = this.props.match.params.cat;
        // let findCat = resultCat.data.map(z=>z.CatID).includes(catString);
        await this.setState({isLoading : "Filter Result"});
        if (catString === "all") {
          resultProduct = resultProduct.data;
          document.title = `ธงแสงโลหะภัณฑ์ - Category - All Products`;
          await this.setState({products : resultProduct, cat : resultCat.data, total : resultProduct.length, currentCatName : "All Products" });
          this.changeProductsView();
        }
        else {
          resultProduct = resultProduct.data.filter(function(item){
            return item.CatID===catString;         
          });
      
          let indexCurrentCat = resultCat.data.findIndex((item, i) =>{
            return item.CatID === catString;
          });


          if (resultProduct.length !== 0) {
            await this.setState({isLoading : "Rendering"});
            document.title = `ธงแสงโลหะภัณฑ์ - Category - ${resultCat.data[indexCurrentCat].CategoryName.toUpperCase()}`;
            await this.setState({products : resultProduct, cat : resultCat.data, total : resultProduct.length, currentCatName : resultCat.data[indexCurrentCat].CategoryName});
            this.changeProductsView();
            console.log(this.state);
          }
          else {
            this.props.history.push('/category');
          }
        }
      }
      catch(e) {
        setTimeout(() => { this.setState({noTry : this.state.noTry+=1}); this.getData(); }, 3000);
      }
    }
    
    
  }
 
  handleClick(offset) {
    console.log(offset);
    this.setState({isLoading : true}, () => {
      this.setState({ offset },() => {this.changeProductsView()});
    });
  }

  changeProductsView = () => {
    let selectedProducts = [];
    for (let i = this.state.offset; i<=this.state.offset+this.state.steps-1;i++) {
      if (this.state.products[i]) {
        selectedProducts.push(this.state.products[i]);
      }
    }
    this.setState({currentProductsView : selectedProducts, isLoading: false});
  }

  renderProducts = () => {
    let renderThis = this.state.currentProductsView.map(x => {
      return <div className="col-6 col-md-2 d-flex align-items-stretch my-2" key={x.ProductCode}>
        <Card className="card"component={RouterLink} to={`/category/${x.CatID}/${x.ProductID}`} style={{textDecoration:"none"}}>
            <img className="card-img" alt={x.CategoryName} style={{maxHeight:"105px"}} src={require(`../picture/${x.Picture}`)} onError={(e)=>{e.target.onerror = null; e.target.src="http://ts1971.com/images/img-not-found.png"}}/>
            
            <div className="card-body d-flex flex-column">
              <div><h6 className="card-title">{x.ProductName}</h6></div>
              <div className="mt-auto">
                
                <h6 className="card-subtitle mb-2 text-muted">Code: {x.ProductCode}</h6>
              </div>
            </div>
        </Card>
      </div>
      
    });
    return <div className="row">{renderThis}</div>
  }

  renderSelect = () => {
    //logic


    //logic
    if (this.state.currentProductsView) {
      return <div className="bg-white p-3 shadow-1 rounded">
        <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/" component={RouterLink} className="anchor-change">
              <span>{Localization.home}</span>
            </Link>
            <Link color="inherit" to="/category" component={RouterLink}>
            <span>{Localization.brands}</span>
            </Link>
            <Typography color="textPrimary"><span>{this.state.currentCatName.toUpperCase()}</span></Typography>
          </Breadcrumbs>
          <div className="div-divider my-3 d-block d-lg-none"></div>
          <div className="text-md-right text-center"><h4 className="text-uppercase underline-head pb-2 pt-3">{this.state.currentCatName}</h4></div>
        </div>
        <div className="div-divider my-3 d-none d-lg-block"></div>
        <div>
          {this.renderProducts()}
        </div>
        <div className="d-flex justify-content-center">
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={this.state.steps}
              offset={this.state.offset}
              total={this.state.total}
              onClick={(e, offset) => this.handleClick(offset)}
            />
          </MuiThemeProvider>
        </div>
      </div>
    }
  }
 
  render() {
    if (this.state.isLoading) {
      return <Loading text={this.state.isLoading} noTry={this.state.noTry}/>
    }
    return (
      <div className="container mt-5">
        {this.renderSelect()}
      </div>
    );
  }
}
 
export default App;