import React, { Component } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import { Link as RouterLink } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Divider } from '@material-ui/core';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
import EmailIcon from '@material-ui/icons/Email';

import FBIcon from '../icon/fb.png';
import ShopeeIcon from '../icon/shopee.png';
import LineIcon from '../icon/line-icon.png';
import IconButton from '@material-ui/core/IconButton';
import LineFriend from '../component/LineFriend';

import Localization from '../component/Localization';
import Loading from '../component/Loading';
 
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      offset: 0,
      product : null,
      isLoading : true,
      currentCatName : "",
      noTry : 0,
     };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async() => {
    if (this.state.noTry === 10) {
      this.setState({isLoading : "Try again later, Server's offline."});
    }
    else {
      try {
        await this.setState({isLoading : "Getting Products"});
        let resultProduct = await axios.get("/pullProducts.php");
        await this.setState({isLoading : "Getting Category"});
        let resultCat = await axios.get("/pullCat.php");
    
        let productString = this.props.match.params.product;
        let catString = this.props.match.params.cat;
        // let findCat = resultCat.data.map(z=>z.CatID).includes(catString);
        await this.setState({isLoading : "Filter Result"});
        resultProduct = resultProduct.data.filter(function(item){
          return item.ProductID===productString;         
        });
    
        resultProduct = resultProduct[0];
        await this.setState({isLoading : "Rendering"});
        if(resultProduct) {
          let indexCurrentCat = resultCat.data.findIndex((item, i) =>{
            return item.CatID === resultProduct.CatID;
          });
          
          console.log(productString, catString, resultProduct);
          document.title = `ธงแสงโลหะภัณฑ์ - ${resultCat.data[indexCurrentCat].CategoryName} - ${resultProduct.ProductCode}`;
          await this.setState({product : resultProduct, currentCatName : resultProduct.CatID, isLoading : false});  
        }
        else {
          this.props.history.push('/category');
        }
      }
      catch(e) {
        setTimeout(() => { this.setState({noTry : this.state.noTry+=1}); this.getData(); }, 3000);
      }
    }

    
    
  }
  render() {
    if (this.state.isLoading) {
      return <Loading text={this.state.isLoading} noTry={this.state.noTry}/>
    }
    return (
      <div className="container mt-1 mt-md-5 bg-white p-3 py-5 shadow-1">
        <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/" component={RouterLink} className="anchor-change">
              <span>{Localization.home}</span>
            </Link>
            <Link color="inherit" to={`/category/`} component={RouterLink}>
              <span>{Localization.brands}</span>
            </Link>
            <Link color="inherit" to={`/category/${this.state.product.CatID}`} component={RouterLink}>
              <span>{this.state.currentCatName.toUpperCase()}</span>
            </Link>
            <Typography color="textPrimary"><span>{this.state.product.ProductName}</span></Typography>
          </Breadcrumbs>
          <div className="div-divider my-3 d-block d-lg-none"></div>
          <div className="text-md-right text-center"><h4 className="text-uppercase underline-head pb-2 pt-3">{this.state.product.ProductCode}</h4></div>
        </div>
        <div className="div-divider my-3 d-none d-lg-block"></div>
        <div>
          <div className="row d-flex align-items-center p-4">
            <div className="col-md-6 col-12">
              <img className="img-thumbnail w-100" alt={this.state.product.ProductName} src={require(`../picture/${this.state.product.Picture}`) }  onError={(e)=>{e.target.onerror = null; e.target.src="http://ts1971.com/images/img-not-found.png"}} />
            </div>
            <div className="col-md-6 col-12">
              <div className="text-center text-md-right px-5 py-3">
                <h3>{this.state.product.ProductName}</h3>
                <p className="text-muted">Code : {this.state.product.ProductCode}</p>
              </div>
              <Divider variant="middle" className="m-3"/>
              <div className="row">
                <div className="col-6 text-center">
                  <p className="">{Localization.status}</p>
                </div>
                <div className="col-6 text-left">
                  <p><CheckCircleIcon/> {Localization.inStock}</p>
                </div>
              </div>
              <Divider variant="middle" className="m-3"/>
              <div className="row">
                <div className="col-12 col-md-6 text-center">
                  <p className="">{Localization.howToBuy}</p>
                </div>
                <div className="col-12 col-md-6 text-left">
                  <div className="">
                    <div style={{maxHeight:"64px", height:"32px", width:"32px"}} className="d-flex flex-row align-items-center mb-3">
                      <img src={LineIcon} className="img-fluid mx-3" alt="LINE ICON"/>
                      <h5 className="m-0">LINE</h5>
                    </div>
                    <div className="text-center">
                      <p className="font-weight-light text-muted text-left">{Localization.howToFromLine} <u>{this.state.product.ProductCode}</u></p>
                      <LineFriend classNam="align-self-center"/>
                    </div>
                  </div>
                  <Divider variant="middle" />
                  <div className="my-3">
                    <div style={{maxHeight:"64px", height:"32px", width:"32px"}} className="d-flex flex-row align-items-center mb-3">
                      <img src={ShopeeIcon} className="img-fluid mx-3" alt="LAZ ICON"/>
                      <h5 className="m-0">Shopee</h5>
                    </div>
                    <RouterLink to="https://shopee.co.th/rexdenki"><span>{Localization.howToFromLaz} Shopee</span></RouterLink>
                  </div>
                  <Divider variant="middle" />
                  <div className="my-3">
                    <div style={{maxHeight:"64px", height:"32px", width:"32px"}} className="d-flex flex-row align-items-center mb-3">
                      <img src={FBIcon} className="img-fluid mx-3" alt="FB ICON"/>
                      <h5 className="m-0">Facebook</h5>
                    </div>
                    <RouterLink to="https://www.facebook.com/REX-DENKI-413319258834527"><span>{Localization.howToFromFB} Facebook</span></RouterLink>
                  </div>
                </div>
              </div>
              <Divider variant="middle" className="m-3"/>
              <div className="row d-flex align-items-center">
                <div className="col-6 text-center">
                  <p className="m-0">{Localization.share}</p>
                </div>
                <div className="col-6 text-left">
                  <IconButton color="inherit" onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${window.location.href}`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400") }>
                    <FacebookIcon className="text-yellow"/>
                  </IconButton>
                  <IconButton color="inherit" onClick={() => window.open(`https://twitter.com/intent/tweet?url=${window.location.href}&text=${this.state.product.ProductName}`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400") }>
                    <TwitterIcon className="text-yellow"/>
                  </IconButton>
                  <IconButton color="inherit" onClick={() => window.open(`http://pinterest.com/pin/create/link/?url=${window.location.href}`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400") }>
                    <PinterestIcon className="text-yellow"/>
                  </IconButton>
                  <IconButton color="inherit" onClick={() => window.open(`mailto:?subject=Product from TS1971 ${this.state.product.ProductName}&amp;body=Check out this product ${window.location.href}.`, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400") }>
                    <EmailIcon className="text-yellow"/>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default App;