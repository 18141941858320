/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Localization from './Localization';
import {withRouter} from 'react-router-dom';

class MyComponent extends React.Component {
  userClick = (e,v) => {
    if (v) {
      this.props.history.push(`/category/${v.CatID}/${v.ProductID}`)
    }
  }
  render() {
    return (
      <Autocomplete
        onChange = {this.userClick}
        id="highlights-demo"
        style={{ width: 200 }}
        options={this.props.products}
        getOptionLabel={option => option.ProductName}
        renderInput={params => (
          <TextField {...params} label={Localization.searchInput} variant="outlined" fullWidth margin="normal" />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.ProductName, inputValue);
          const parts = parse(option.ProductName, matches);
  
          return (
            <div>
              {parts.map((part, index) => (
                <span color="text-dark" key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    );
  }
}

export default withRouter(MyComponent);